<template>
  <div>
    <vue-pdf-app class="alturaPdf" :pdf="arquivoPdf" :config="config"></vue-pdf-app>
  </div>
</template>

<script>
import arquivoPdf from "@/assets/manual-uso-aplicao-questionarios/manual-cora-v-1.pdf";
export default {
  data() {
    return {
      arquivoPdf,
      config: {
        toolbar: false
      },
    }
  }
}
</script>
<style scoped>
.alturaPdf{
  height: 88vh;
}
.pdf-app.light {
  --pdf-app-background-color: #F8F8F8;
  }
</style>